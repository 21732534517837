
const Projects = [
  {
    id: 1,
    title: "Hidden Gems",
    description: "Desktop Website, HTML/CSS/JS",
    image: "/images/project-1-img.png",
    link: "https://fjordklyngen.mjkieldsen.com",
    githubLink: "github",
  },
  {
    id: 2,
    title: "Nørreris Put&Take",
    description: "Responsive mobile-first website, HTML/CSS/JS",
    image: "/images/project-2-img.webp",
    link: "phttps://putandtake.mjkieldsen.com",
    githubLink: "github",
  },
  {
    id: 3,
    title: "Minotaur Javascript Game",
    description: "Game coded exclusively with javascript, HTML/CSS/JS",
    image: "/images/project-3-img.webp",
    link: "https://games.mjkieldsen.com",
    githubLink: "https://github.com",
  },
  {
    id: 4,
    title: "MusicMania",
    description: "Ecommerce website was with Woocommerce, Wordpress",
    image: "/images/project-4-img.png",
    link: "https://shop.mjkieldsen.com",
    githubLink: "github",
  },
]

export default Projects;