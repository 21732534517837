import React from 'react';
import "./Home.css"

const Home = () => {
  return (
    <div className='background'>
      <div className="text-container">
        <span>UX/UI + WEB DEVELOPMENT</span>
        <h1>
          Collaborate with a developer who listens and cares.
        </h1>
        <p>
            Danish Web Developer based in Aarhus📍 <br />
            I specialize in creating responsive, high- <br />performance
            websites with clean code.
        </p>
      </div>
    </div>
  );
};

export default Home;