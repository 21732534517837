// src/Contact.js

import React from 'react';

const Contact = () => {
  return (
    <div style={{ padding: '20px' }}>
      <h1>Contact Me</h1>
      <p>You can reach me at example@example.com.</p>
      {/* Add a contact form or additional contact details here */}
    </div>
  );
};

export default Contact;