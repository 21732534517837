import React from 'react'
import { NavLink } from 'react-router-dom'
import './Navbar.css'

const Navbar = () => {
  return (
    <nav className="navbar">
      <div className="navbar-logo">
            <h2>Marius Kieldsen</h2>
      </div>
      <ul className="navbar-menu">
        <li>
          <NavLink 
            to="/projects" 
            className={({ isActive }) => isActive ? 'active' : ''}
          >
            Projects
          </NavLink>
        </li>
        <li>
          <NavLink 
            to="/about" 
            className={({ isActive }) => isActive ? 'active' : ''}
          >
            About
          </NavLink>
        </li>
        <li>
          <NavLink 
            to="/contact" 
            className="contact-button"
          >
            Contact Me
          </NavLink>
        </li>
      </ul>
    </nav>
  );
};

export default Navbar